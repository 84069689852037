import { FeatureData } from '@innedit/innedit';
import { FeatureType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../../components/View';
import Form from '../../../../../../../containers/Espace/Form';
import comment from '../../../../../../../params/contact/comment.json';
import TemplateEspace from '../../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../../utils/requireUser';

const PageEspaceContactCommentUpdate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, user, params: { espaceId, contactId, commentId } }) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        docId={commentId}
        itemPathnamePrefix={`/espaces/${espaceId}/contacts/${contactId}/comments/`}
        model={
          new FeatureData<FeatureType>({
            espaceId,
            collectionName: 'comments',
            params: comment,
            parentCollectionName: 'contacts',
            parentId: contactId,
          })
        }
        name="comment"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageEspaceContactCommentUpdate);
